
























































































































































































































































































































































































































.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
